const { serialize } = require("../../utils");
export default {
  namespaced: true,
  state: {
    path: "aljabr/groups",
  },
  actions: {
    get({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.aljabr.selectedBranch._id;
      options["academicYear"] = rootState.aljabr.selectedAcademicYear._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    list({ dispatch, state, rootState }, academicYearID) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/list/?branch=${rootState.aljabr.selectedBranch._id}&academicYear=${academicYearID ? academicYearID : rootState.aljabr.selectedAcademicYear._id}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    last({ dispatch, state, rootState }) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/last/?branch=${rootState.aljabr.selectedBranch._id}&academicYear=${rootState.aljabr.selectedAcademicYear._id}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    add({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        data["branch"] = rootState.aljabr.selectedBranch._id;
        data["academicYear"] = rootState.aljabr.selectedAcademicYear._id;
        data["company"] = { short: rootState.company.short.toLowerCase() };
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/add`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    listWithStudents({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/list-with-students/?branch=${rootState.aljabr.selectedBranch._id}&academicYear=${options.academicYearID ? options.academicYearID : rootState.aljabr.selectedAcademicYear._id}&disableIfNoContract=${options.disableIfNoContract ? options.disableIfNoContract : false}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    save({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/save`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    id({ dispatch, state, rootState }, id) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/id/${id}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    removeStudent({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/remove-student`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    addKnowledge({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        data["branch"] = rootState.aljabr.selectedBranch._id;
        data["academicYear"] = rootState.aljabr.selectedAcademicYear._id;
        data["company"] = { short: rootState.company.short.toLowerCase() };
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/add-knowledge`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    getKnowledgeGroups({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.aljabr.selectedBranch._id;
      options["academicYear"] = rootState.aljabr.selectedAcademicYear._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/knowledge-groups?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    saveKnowledge({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/save-knowledge`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    knowledGroupId({ dispatch, state, rootState }, id) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/knowledge-group/${id}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    addTeacherToKnowledgeGroup({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/add-teacher-to-knowledge-group`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    removeTeacherFromKnowledgeGroup({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/remove-teacher-from-knowledge-group`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    addStudentToKnowledgeGroup({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/add-student-to-knowledge-group`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    removeStudentFromKnowledgeGroup({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/remove-student-from-knowledge-group`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    addScienceToKnowledgeGroup({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/add-science-to-knowledge-group`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    removeScienceFromKnowledgeGroup({ dispatch, state, rootState }, data) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/remove-science-from-knowledge-group`,
            method: "POST",
            data,
          },
          { root: true }
        );
      }
    },
    listWithStudentsForAddGroup({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/list-with-students-for-add-group?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    remove({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/remove`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    removeKnowledge({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/remove-knowledge`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    addKnowledgeGroupRatings({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add-knowledge-group-ratings`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    "add-students-notebook-etiquette-evolution"({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add-students-notebook-etiquette-evolution`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    depositListWithStudents: ({ dispatch, state, rootState }, options) => {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/deposit-list-with-students?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    moveStudentFromDepositToGroup: ({ dispatch, state, rootState }, data) => {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/move-student-from-deposit-to-group`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
  },
};
