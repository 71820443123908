import Vue from "vue";
import VueRouter from "vue-router";
import Store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: function () {
      return import(/* webpackChunkName: "home" */ "../views/Home.vue");
    },
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/aljabr",
    name: "alJabrLayout",
    component: function () {
      return import(/* webpackChunkName: "alJabrLayout" */ "../views/Layout.vue");
    },
    meta: {
      authRequired: true,
      company: "AlJabr",
    },
    children: [
      {
        path: "director-main",
        name: "AlJabrDirectorMain",
        title: "Asosiy saxifa",
        component: function () {
          return import(/* webpackChunkName: "alJabrDirectorMain" */ "../views/AlJabr/DirectorHome.vue");
        },
        meta: {
          roles: ["director"],
          icon: "mdi-briefcase-account",
          isMain: true,
          company: "aljabr",
          isRouter: true,
        },
      },
      {
        path: "cashier-main",
        name: "AlJabrCashierMain",
        title: "Asosiy saxifa",
        component: function () {
          return import(/* webpackChunkName: "AlJabrCashierMain" */ "../views/AlJabr/CashierHome.vue");
        },
        meta: {
          roles: ["cashier"],
          icon: "mdi-briefcase-account",
          isMain: true,
          company: "aljabr",
          isRouter: true,
        },
      },
      {
        path: "security-main",
        name: "AlJabrSecurityMain",
        title: "Asosiy saxifa",
        component: function () {
          return import(/* webpackChunkName: "AlJabrSecurityMain" */ "../views/AlJabr/SecurityHome.vue");
        },
        meta: {
          roles: ["security"],
          icon: "mdi-briefcase-account",
          isMain: true,
          company: "aljabr",
          isRouter: true,
        },
      },
      {
        path: "director-my-week",
        name: "AlJabrDirectorMyWeek",
        title: "Mening xaftaligim",
        component: function () {
          return import(/* webpackChunkName: "AlJabrEmployeeRegistrations" */ "../views/AlJabr/DirectorMyWeek.vue");
        },
        meta: {
          roles: ["director"],
          icon: "mdi-calendar-clock",
          company: "aljabr",
          isRouter: true,
        },
      },
      {
        path: "contractor-main",
        name: "AlJabrContractorMain",
        title: "Asosiy saxifa",
        component: function () {
          return import(/* webpackChunkName: "AlJabrContractorMain" */ "../views/AlJabr/Contracts.vue");
        },
        meta: {
          roles: ["contractor"],
          icon: "mdi-briefcase-account",
          isMain: true,
          company: "aljabr",
          isRouter: true,
        },
      },
      {
        path: "branches",
        name: "AlJabrBranches",
        title: "Filiallar",
        component: function () {
          return import(/* webpackChunkName: "AlJabrBranches" */ "../views/AlJabr/Branches.vue");
        },
        meta: {
          roles: ["director", "director-deputy", "manager", "platform-manager"],
          icon: "mdi-source-branch",
          company: "aljabr",
          isRouter: true,
        },
      },
      {
        path: "academic-years",
        name: "AlJabrAcademicYears",
        title: "Akademik yillar",
        component: function () {
          return import(/* webpackChunkName: "AlJabrAcademicYears" */ "../views/AlJabr/AcademicYears.vue");
        },
        meta: {
          roles: ["director", "director-deputy", "manager", "platform-manager"],
          icon: "mdi-school",
          company: "aljabr",
          isRouter: true,
        },
      },
      {
        path: "groups",
        name: "AlJabrGroups",
        title: "Sinflar/Guruhlar",
        component: function () {
          return import(/* webpackChunkName: "AlJabrGroups" */ "../views/AlJabr/Groups.vue");
        },
        meta: {
          roles: ["director", "teachers", "cashier", "director-deputy", "manager", "contractor", "head-teacher", "platform-manager", "security"],
          icon: "mdi-account-group",
          company: "aljabr",
          isRouter: true,
        },
      },
      {
        path: "group/:id",
        name: "AlJabrGroup",
        props: true,
        component: function () {
          return import(/* webpackChunkName: "AlJabrGroup" */ "../views/AlJabr/Group.vue");
        },
        meta: {
          roles: ["director", "teachers", "cashier", "director-deputy", "manager", "contractor", "head-teacher", "platform-manager", "security"],
          company: "aljabr",
        },
      },
      {
        path: "knowledge-group/:id",
        name: "AlJabrKnowledgeGroup",
        props: true,
        component: function () {
          return import(/* webpackChunkName: "AlJabrKnowledgeGroup" */ "../views/AlJabr/KnowledgeGroup.vue");
        },
        meta: {
          roles: ["director", "teachers", "cashier", "director-deputy", "manager", "contractor", "head-teacher", "platform-manager", "security"],
          company: "aljabr",
        },
      },
      {
        path: "staffers",
        name: "AlJabrStaffers",
        title: "Xodimlar",
        component: function () {
          return import(/* webpackChunkName: "AlJabrStaffers" */ "../views/AlJabr/Staffers.vue");
        },
        meta: {
          roles: ["director", "cashier", "director-deputy", "manager", "platform-manager", "security"],
          icon: "mdi-badge-account-outline",
          company: "aljabr",
          isRouter: true,
        },
      },
      {
        path: "staffer/:id",
        name: "AlJabrStaffer",
        props: true,
        component: function () {
          return import(/* webpackChunkName: "AlJabrStaffer" */ "../views/AlJabr/Staffer.vue");
        },
        meta: {
          roles: ["director", "cashier", "director-deputy", "manager", "platform-manager", "security"],
          company: "aljabr",
        },
      },
      {
        path: "roles",
        name: "AlJabrRoles",
        title: "Lavozimlar",
        component: function () {
          return import(/* webpackChunkName: "AlJabrRoles" */ "../views/AlJabr/Roles.vue");
        },
        meta: {
          roles: ["director"],
          icon: "mdi-shield-account",
          company: "aljabr",
          isRouter: true,
        },
      },
      {
        path: "students",
        name: "AlJabrStudents",
        title: "O'quvchilar",
        component: function () {
          return import(/* webpackChunkName: "AlJabrStudents" */ "../views/AlJabr/Students.vue");
        },
        meta: {
          roles: ["director", "director-deputy", "manager", "cashier", "contractor", "platform-manager", "head-teacher", "security"],
          icon: "mdi-account-school",
          company: "aljabr",
          isRouter: true,
        },
      },
      {
        path: "student/:id",
        name: "AlJabrStudent",
        props: true,
        component: function () {
          return import(/* webpackChunkName: "AlJabrStudent" */ "../views/AlJabr/Student.vue");
        },
        meta: {
          roles: ["director", "director-deputy", "manager", "cashier", "contractor", "platform-manager", "head-teacher", "security"],
          company: "aljabr",
        },
      },
      {
        path: "sciences",
        name: "AlJabrSciences",
        title: "Fanlar",
        component: function () {
          return import(/* webpackChunkName: "AlJabrSciences" */ "../views/AlJabr/Sciences.vue");
        },
        meta: {
          roles: ["director", "director-deputy", "manager", "head-teacher", "platform-manager"],
          icon: "mdi-alphabetical-variant",
          company: "aljabr",
          isRouter: true,
        },
      },
      {
        path: "cashier",
        name: "AlJabrCashier",
        title: "Kassa",
        component: function () {
          return import(/* webpackChunkName: "AlJabrCashier" */ "../views/AlJabr/Cashier.vue");
        },
        meta: {
          roles: ["director", "cashier"],
          icon: "mdi-cash",
          company: "aljabr",
          isRouter: true,
        },
      },
      {
        path: "staffer-tasks",
        name: "AlJabrStafferTasks",
        title: "Vazifalar",
        component: function () {
          return import(/* webpackChunkName: "AlJabrStafferTasks" */ "../views/AlJabr/StafferTasks.vue");
        },
        meta: {
          roles: ["director", "director-deputy", "manager", "platform-manager"],
          icon: "mdi-checkbox-multiple-marked-outline",
          company: "aljabr",
          isRouter: true,
        },
      },
      {
        path: "contracts",
        name: "AlJabrContracts",
        title: "Shartnomalar",
        component: function () {
          return import(/* webpackChunkName: "AlJabrContracts" */ "../views/AlJabr/Contracts.vue");
        },
        meta: {
          roles: ["director", "director-deputy", "manager", "cashier", "contractor", "platform-manager"],
          icon: "mdi-file-document-multiple-outline",
          company: "aljabr",
          isRouter: true,
        },
      },
      {
        path: "salary",
        name: "AlJabrSalary",
        title: "Oylik hisob-kitobi",
        component: function () {
          return import(/* webpackChunkName: "AlJabrSalary" */ "../views/AlJabr/Salary.vue");
        },
        meta: {
          roles: ["director", "director-deputy", "cashier"],
          icon: "mdi-account-cash-outline",
          company: "aljabr",
          isRouter: true,
        },
      },
      {
        path: "migration",
        name: "AlJabrMigration",
        title: "Migratsiya saxifa",
        component: function () {
          return import(/* webpackChunkName: "AlJabrMigration" */ "../views/AlJabr/Migration.vue");
        },
        meta: {
          roles: ["director"],
          icon: "mdi-cached",
          company: "aljabr",
          isRouter: true,
        },
      },
      {
        path: "telegramBots",
        name: "AlJabrTelegramBots",
        title: "Telegram botlar",
        component: function () {
          return import(/* webpackChunkName: "AlJabrTelegramBots" */ "../views/AlJabr/TelegramBots.vue");
        },
        meta: {
          roles: ["director", "director-deputy", "manager", "platform-manager", "security"],
          icon: "mdi-forum",
          company: "aljabr",
          isRouter: true,
        },
      },
      {
        path: "lessonsSchedule",
        name: "AlJabrLessonsSchedule",
        title: "Darslar jadvali",
        component: function () {
          return import(/* webpackChunkName: "AlJabrLessonsSchedule" */ "../views/AlJabr/LessonsSchedule.vue");
        },
        meta: {
          roles: ["director", "director-deputy", "head-teacher"],
          icon: "mdi-table-large",
          company: "aljabr",
          isRouter: true,
        },
      },
      {
        path: "faceIdDevices",
        name: "AlJabrFaceIdDevices",
        title: "Face ID qurilmalari",
        component: function () {
          return import(/* webpackChunkName: "AlJabrFaceIdDevices" */ "../views/AlJabr/FaceIdDevices.vue");
        },
        meta: {
          roles: ["director", "security", "platform-manager"],
          icon: "mdi-face-recognition",
          company: "aljabr",
          isRouter: true,
        },
      },
      {
        path: "kpi-automations",
        name: "AlJabrKPIAutomations",
        title: "KPI Avtomatlashtirish",
        component: function () {
          return import(/* webpackChunkName: "AlJabrKPIAutomations" */ "../views/AlJabr/KPIAutomations.vue");
        },
        meta: {
          roles: ["director"],
          icon: "mdi-arrow-decision-auto-outline",
          company: "aljabr",
          isRouter: true,
        },
      },
      {
        path: "sim-cards",
        name: "AlJabrSimCards",
        title: "Sim kartalar",
        component: function () {
          return import(/* webpackChunkName: "AlJabrSimCards" */ "../views/AlJabr/SimCards.vue");
        },
        meta: {
          roles: ["director", "platform-manager", "security"],
          icon: "mdi-sim",
          company: "aljabr",
          isRouter: true,
        },
      },
      {
        path: "sales-department",
        name: "AlJabrSalasDepartment",
        title: "Sotuv bo'limi",
        component: function () {
          return import(/* webpackChunkName: "AlJabrSalasDepartment" */ "../views/AlJabr/SalesDepartment/Main.vue");
        },
        meta: {
          roles: ["director", "sales-manager"],
          icon: "mdi-sale",
          company: "aljabr",
          isRouter: true,
        },
      },
      {
        path: "company",
        name: "AlJabrCompany",
        title: "Korxona",
        component: function () {
          return import(/* webpackChunkName: "AlJabrCompany" */ "../views/AlJabr/Company/Main.vue");
        },
        meta: {
          roles: ["director", "platform-manager"],
          icon: "mdi-domain",
          company: "aljabr",
          isRouter: true,
        },
      },
    ],
  },
  {
    path: "/abco",
    name: "ABCOLayout",
    component: function () {
      return import(/* webpackChunkName: "ABCOLayout" */ "../views/Layout.vue");
    },
    meta: {
      authRequired: true,
      company: "abco",
    },
    children: [
      {
        path: "director-main",
        name: "ABCODirectorMain",
        title: "Asosiy saxifa",
        component: function () {
          return import(/* webpackChunkName: "ABCODirectorMain" */ "../views/ABCO/DirectorHome.vue");
        },
        meta: {
          roles: ["director"],
          icon: "mdi-briefcase-account",
          isMain: true,
          company: "abco",
        },
      },
      {
        path: "branches",
        name: "ABCOBranches",
        title: "Filiallar",
        component: function () {
          return import(/* webpackChunkName: "ABCOBranches" */ "../views/ABCO/Branches.vue");
        },
        meta: {
          roles: ["director"],
          icon: "mdi-source-branch",
          company: "abco",
        },
      },
      {
        path: "groups",
        name: "ABCOGroups",
        title: "Sinflar",
        component: function () {
          return import(/* webpackChunkName: "ABCOGroups" */ "../views/ABCO/Guruhlar.vue");
        },
        meta: {
          roles: ["director", "teachers", "cashier", "admin"],
          icon: "mdi-account-group",
          company: "abco",
        },
      },
      {
        path: "staffers",
        name: "ABCOStaffers",
        title: "Xodimlar",
        component: function () {
          return import(/* webpackChunkName: "ABCOStaffers" */ "../views/ABCO/Staffers.vue");
        },
        meta: {
          roles: ["director", "cashier", "admin"],
          icon: "mdi-badge-account-outline",
          company: "abco",
        },
      },
      {
        path: "staffer/:id",
        name: "ABCOStaffer",
        props: true,
        component: function () {
          return import(/* webpackChunkName: "ABCOStaffer" */ "../views/ABCO/Staffer.vue");
        },
        meta: {
          company: "abco",
        },
      },
      {
        path: "roles",
        name: "ABCORoles",
        title: "Lavozimlar",
        component: function () {
          return import(/* webpackChunkName: "ABCORoles" */ "../views/ABCO/Roles.vue");
        },
        meta: {
          roles: ["director"],
          icon: "mdi-shield-account",
          company: "abco",
        },
      },
      {
        path: "students",
        name: "ABCOStudents",
        title: "O'quvchilar",
        component: function () {
          return import(/* webpackChunkName: "ABCOStudents" */ "../views/ABCO/Students.vue");
        },
        meta: {
          roles: ["director", "cashier", "admin"],
          icon: "mdi-account-school",
          company: "abco",
        },
      },
      {
        path: "student/:id",
        name: "ABCOStudent",
        props: true,
        component: function () {
          return import(/* webpackChunkName: "ABCOStudent" */ "../views/ABCO/Student.vue");
        },
        meta: {
          company: "abco",
        },
      },
      {
        path: "cashier",
        name: "ABCOCashier",
        title: "Kassa",
        component: function () {
          return import(/* webpackChunkName: "ABCOCashier" */ "../views/ABCO/Cashier.vue");
        },
        meta: {
          roles: ["director", "cashier", "admin"],
          icon: "mdi-cash",
          company: "abco",
        },
      },
      {
        path: "workloads",
        name: "ABCOWorkloads",
        title: "Vazifalar",
        component: function () {
          return import(/* webpackChunkName: "ABCOWorkloads" */ "../views/ABCO/Workloads.vue");
        },
        meta: {
          roles: ["director", "cashier", "admin"],
          icon: "mdi-checkbox-multiple-marked-outline",
          company: "abco",
        },
      },
      {
        path: "contracts",
        name: "ABCOContracts",
        title: "Shartnomalar",
        component: function () {
          return import(/* webpackChunkName: "ABCOContracts" */ "../views/ABCO/Contracts.vue");
        },
        meta: {
          roles: ["director", "cashier", "admin"],
          icon: "mdi-file-document-multiple-outline",
          company: "abco",
        },
      },
      {
        path: "employee-registrations",
        name: "ABCOEmployeeRegistrations",
        title: "Xodimlarning ish vaqtini kuzatish",
        component: function () {
          return import(/* webpackChunkName: "ABCOEmployeeRegistrations" */ "../views/ABCO/EmployeeRegistrations.vue");
        },
        meta: {
          roles: ["director", "cashier", "admin"],
          icon: "mdi-calendar-clock",
          company: "abco",
        },
      },
    ],
  },
  {
    path: "/telegram-web-app",
    name: "TelegramWebAppLayout",
    component: function () {
      return import(/* webpackChunkName: "TelegramWebAppLayout" */ "../views/Layout.vue");
    },
    children: [
      {
        path: "staffers",
        name: "TelegramWebAppStaffersLayout",
        component: function () {
          return import(/* webpackChunkName: "TelegramWebAppStaffersLayout" */ "../views/TelegramWebApp/Layout.vue");
        },
        children: [
          {
            path: "director",
            name: "TelegramWebAppStaffersDirector",
            component: function () {
              return import(/* webpackChunkName: "TelegramWebAppStaffersDirector" */ "../views/TelegramWebApp/staffers/Director.vue");
            },
          },
          {
            path: "staffer",
            name: "TelegramWebAppStaffersStaffer",
            component: function () {
              return import(/* webpackChunkName: "TelegramWebAppStaffersStaffer" */ "../views/TelegramWebApp/staffers/Staffer.vue");
            },
          },
          {
            path: "media-staffer",
            name: "TelegramWebAppMediaStaffersStaffer",
            component: function () {
              return import(/* webpackChunkName: "TelegramWebAppMediaStaffersStaffer" */ "../views/TelegramWebApp/staffers/media-staffer/Media-staffer.vue");
            },
          },
          {
            path: "security",
            name: "TelegramWebAppSecurityStaffer",
            component: function () {
              return import(/* webpackChunkName: "TelegramWebAppSecurityStaffer" */ "../views/TelegramWebApp/staffers/security/Security-staffer.vue");
            },
          },
          {
            path: "contractor",
            name: "TelegramWebAppContractorStaffer",
            component: function () {
              return import(/* webpackChunkName: "TelegramWebAppContractorStaffer" */ "../views/TelegramWebApp/staffers/contractor/Main.vue");
            },
          },
          {
            path: "head-teacher",
            name: "TelegramWebAppHeadTeacherLayout",
            component: function () {
              return import(/* webpackChunkName: "TelegramWebAppHeadTeacherLayout" */ "../views/TelegramWebApp/staffers/head-teacher/layout.vue");
            },
            children: [
              {
                path: "chats",
                name: "TelegramWebAppHeadTeacherChats",
                component: function () {
                  return import(/* webpackChunkName: "TelegramWebAppHeadTeacherChats" */ "../views/TelegramWebApp/staffers/head-teacher/chats.vue");
                },
              },
            ],
          },
          {
            path: "teacher",
            name: "TelegramWebAppTeacherLayout",
            component: function () {
              return import(/* webpackChunkName: "TelegramWebAppTeacherLayout" */ "../views/TelegramWebApp/staffers/teacher/layout.vue");
            },
            children: [
              {
                path: "chats",
                name: "TelegramWebAppTeacherChats",
                component: function () {
                  return import(/* webpackChunkName: "TelegramWebAppTeacherChats" */ "../views/TelegramWebApp/staffers/teacher/chats.vue");
                },
              },
            ],
          },
        ],
      },
      {
        path: "parents",
        name: "TelegramWebAppParentsLayout",
        component: function () {
          return import(/* webpackChunkName: "TelegramWebAppParentsLayout" */ "../views/TelegramWebApp/Layout.vue");
        },
        children: [
          {
            path: "parent",
            name: "TelegramWebAppParentsParent",
            component: function () {
              return import(/* webpackChunkName: "TelegramWebAppParentsParent" */ "../views/TelegramWebApp/parents/Parent.vue");
            },
          },
        ],
      },
      {
        path: "specialAudioTest",
        name: "TelegramWebAppSpecialAudioTest",
        component: function () {
          return import(/* webpackChunkName: "TelegramWebAppSpecialAudioTest" */ "../views/TelegramWebApp/parents/SpecialAudioTest.vue");
        },
      },
    ],
  },
  {
    path: "*",
    component: function () {
      return import(/* webpackChunkName: "pageNotFound" */ "../views/404.vue");
    },
  },
  {
    path: "/login",
    name: "Login",
    title: "Kirish",
    component: function () {
      return import(/* webpackChunkName: "login" */ "../views/Login.vue");
    },
  },
];

const router = new VueRouter({
  mode: process.env.IS_ELECTRON ? "hash" : "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  Store.dispatch("INIT_AUTH").then((user) => {
    if (to.matched.some((route) => route.meta.authRequired)) {
      if (user) {
        if (Store.state[to.meta.company]) {
          const profileRoles = Store.state[to.meta.company].userRoles;
          if (to.meta.roles.find((role) => profileRoles.includes(role))) {
            next();
          } else {
            next("/");
          }
        } else {
          next();
        }
      } else next("/login");
    } else {
      next();
    }
  });
});

export default router;
